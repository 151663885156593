import React, { Suspense, useContext, createContext, useState } from "react";

import { Route, Routes } from "react-router-dom";

import Main from "app/pages/main";
import Login from "app/pages/login";
import Signup from "app/pages/signup";
import Verification from "app/pages/verification";

import TripsPage from "app/pages/trips";
import TripViewPage from "app/pages/trips/view";
import TripBookedPage from "app/pages/trips/booked";
import TripPaymentPage from "app/pages/trips/payment";

import TermsPage from "app/pages/terms";
import HelpPage from "app/pages/help_page";
import UnlockEmail from "app/pages/unlock_email";
import ResetPassword from "app/pages/reset_password";
import ForgotPassword from "app/pages/forgot_password";
import EmailConfirmation from "app/pages/email_confirmation";

import NotFound from "app/pages/not_found";

// const AccountSettings = React.lazy(() => import("./pages/account_settings"));

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />

      <Route path="/trips" element={<TripsPage />} />
      <Route path="/trips/:trip_id" element={<TripViewPage />} />
      <Route
        path="/trips/:trip_id/charges/:charge_id/payment"
        element={<TripPaymentPage />}
      />
      <Route
        path="/trips/:trip_id/charges/:charge_id/booked"
        element={<TripBookedPage />}
      />

      <Route path="/help" element={<HelpPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/verification" element={<Verification />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path="/email_confirmation" element={<EmailConfirmation />} />
      <Route path="/unlock_email" element={<UnlockEmail />} />
      <Route path="/reset_password" element={<ResetPassword />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

{
  /* <Route
path="/account_settings/*"
element={
  <Suspense fallback={<Loading />}>
    <AccountSettings />
  </Suspense>
}
/> */
}
